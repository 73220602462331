.carousel {
    overflow: hidden;
}

.carousel-inner {
    
    transition: transform 0.3s;
    white-space: nowrap;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
}

.indicators {
    display: flex;
    justify-content: center;
} 

.indicators > button {
    margin: 5px;
    margin-top: 30px;
}

.indicators > button.active {
    background-color: rgba(20, 106, 245, 0.8);
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .carousel-item {
        height: 250px;
        width: auto;
    }
}