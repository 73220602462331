.general-container {
    margin-left: 180px;
    margin-right: 180px;
    background-color: rgba(139, 197, 247,0.9);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.content-container {
    padding-top: 50px;
    padding-bottom: 70px;
    margin-left: 70px;
    margin-right: 70px;
}

