.contact-page {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #0c456b;
    font-weight: bold;
}

.contact-email-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.contact-email {
    font-size: 20px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    .contact-page {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

}