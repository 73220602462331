.app-content {
    display: grid;
    grid-template-columns: 12% 38% 38% 12%;
    grid-template-rows: 600px 600px;
    gap: 7% 0%;
    margin-top: 80px;
    height: 1400px;
    
}

.app-title-container {
    height: 60px;
    width: 330px;
    background-color: rgba(20, 106, 245, 0.8);
    margin: auto;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    line-height: 60px;
}

.app-title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: white;
    margin: auto;
}

.app-screenshot-1 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    height: 600px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    
}

.app-screenshot-2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
    height: 600px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.app-intro-part {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

.app-descript-part {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
}

@media only screen and (max-width: 600px) {
    .app-content {
        margin-top: 0px;
        grid-template-columns: 100%;
        
        grid-template-rows: auto;
        height: auto;
        gap: 0% 0%;
    }
    .app-title {
        font-size: 22px;
    }
    .app-title-container {
        width: 280px;
        height: 40px;
        border-radius: 10px;
        line-height: 40px;
    }
    .app-screenshot-1 {
        grid-column-start: 1;
        grid-column-end: 1;
       
        height: 430px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 20px;
        grid-row: span;
    }
    
    .app-screenshot-2 {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        height: 430px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }

    .app-intro-part {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        grid-row: span;
    }
    
    .app-descript-part {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 4;
        grid-row-end: 4;
        height: 430px;
    }
}