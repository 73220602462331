.profile-content {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    margin-top: 2%;
}

.profile-content-pic01 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    
}

.profile-content-main {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    font-family: 'Laila', sans-serif;
}

.profile-content-pic02 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 0px;
    padding-bottom: 0px;
}

.profile-images {
    width: 80%;
    height: auto;
}

.profile-content-name {
    font-size: 25px;
    font-style: bold;
    margin-top: 0px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
    .profile-images {
        display: none;
    }
    .profile-content {
        grid-template-columns: 0% 100% 0%;
    }
}