.nav-bar {
    background-color: rgba(0, 0, 0, 0);
}

.nav-title {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 35px;
    padding-top: 80px;
    padding-bottom: 10px;
}

.nav-menu {
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 30% 10% 10% 10% 10% 30%;
}

.nav-menu-home {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 30px;
    padding-bottom: 0px;
}

.nav-menu-profile {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 30px;
    padding-bottom: 0px;
}

.nav-menu-apps {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 30px;
    padding-bottom: 0px;
}

.nav-menu-contact {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
    padding-top: 30px;
    padding-bottom: 0px;
}

.nav-jess-pic {
    height: 200px;
    display: inline-block;
}

.nav-jess-name {
    display: inline-block;
    margin-right: 60px;
    vertical-align: top;
}

.nav-jess-title {
    display: block;
    font-size: 25px;
}

@media only screen and (max-width: 600px) {
    .nav-menu {
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: 20% 15% 15% 15% 15% 20%;
    }

    .nav-title {
        padding-top: 30px;
    }
    .nav-jess-name {
        font-size: 25px;
    }
    .nav-jess-title {
        font-size: 20px;
    }
    .nav-jess-pic {
        height: 120px;
    }
    .nav-menu {
        padding-top: 0px;
        padding-bottom: 20px;
    }
    .nav-menu-home {
        padding-top: 15px;
    }
    .nav-menu-profile {
        padding-top: 15px;
    }
    .nav-menu-apps {
        padding-top: 15px;
    }
    .nav-menu-contact {
        padding-top: 15px;
    }
}

/*
background-color: rgba(176, 237, 152, 0.7);
*/

