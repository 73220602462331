
.app-descriptions {
    
    grid-template-rows: 120px 80px 80px 80px 80px 80px;
    height: 600px;
    font-size: 20px;
    background-color: rgba(139, 197, 247,0.9);
    /*
    background-color: rgba(162, 224, 164,0.7);
    repeat(6, 80px);
    */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: grid;
    grid-template-columns: 30% 70%;
    text-align: left;
}

.title {
    margin-left: 30px;
}

.content {
    margin-right: 30px;
}

.title-description {
    margin-top: 50px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    
}

.content-description {
    margin-top: 50px;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    
}

.title-platform {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

.content-platform {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
}

.title-language {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
}

.content-language {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
}

.title-repo {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
    
}

.content-repo {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
    word-break: break-all;
}

.title-date {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
}

.content-date {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 5;
}

.title-policy {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 6;
    grid-row-end: 6;
}

.content-policy {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 6;
}

@media only screen and (max-width: 600px) {
    .app-descriptions {
        height: auto;
        font-size: 16px;
        line-height: 22px;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 30px;
        grid-template-rows: 90px repeat(5, 60px);
    }
    .title-description {
        margin-top: 30px;
    }
    .content-description {
        margin-top: 30px;
    }
}