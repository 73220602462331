.footer {
    background: url(../images/bb_profile_back.png);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-name {
    color: white;
    margin-right: 30px;
    font-weight: bold;
    font-size: 20px;
}

.IG {
    background-color: rgba(0,0,0,0);
    height: 50px;
    vertical-align: middle;
}

.contacts {
    background-color: rgba(0,0,0,0);
    height: 60px;
    vertical-align: middle;
    margin-left: 10px;
}

@media only screen and (max-width: 600px) {
    .footer {
        height: 70px;
    }
    .footer-name {
        margin-right: 15px;
        font-size: 15px;
    }
    .IG {
        height: 30px;
    }
    .contacts {
        height: 40px;
        margin-left: 6px;
    }

}
