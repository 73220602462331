.delete-form-container {
    font-size: 20px;
    color: #0c456b;
    padding-top: 20px;
}

.content {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.drop-down {
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 40px;
}

.delete-form-label-email {
    display: block;
    font-size: 20px;
    margin-bottom: 30px;
    color: black;
}

.delete-form-inputBox-email {
    font-size: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    line-height: 25px;
    font-size: 20px;
    color: #0c456b;
    margin-top: 5px;
    background-color: rgba(0,0,0,0);
    border: none;
    border-bottom: 2px solid blue;
    padding: 10px 15px;
    outline: none;
}

.delete-form-submit-button {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
    width: 120px;
    color: #0c456b;
    background-color: rgba(0,0,0,0);
    border: 2px solid blue;
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .content {
        font-size: 17px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 60px;
        margin-right: 60px;
    }
    .delete-form-label-email {
        font-size: 17px;
        margin-bottom: 20px;
    }
    .delete-form-inputBox-email {
        font-size: 17px;
        margin-bottom: 15px;
        line-height: 23px;
    }
    .drop-down {
        margin-left: 60px;
        margin-right: 60px;
        margin-bottom: 20px;
    }
    
    .delete-form-submit-button {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 17px;
        width: 100px;
        
    }
}