

.contactForm-container {
    display: grid;
    grid-template-columns: 35% 30% 35%;
    margin-top: 40px;
}

.contact-comment-label-email, .contact-comment-label-comment {
    font-size: 20px;
    color: #0c456b;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 15px;
}

.contact-comment-label-email {
    grid-column-start: 2;
    text-align: left;
}

.contact-comment-label-comment {
    grid-column-start: 2;
    text-align: left;
    grid-row-start: 3;
}

.contact-comment-inputBox-email {
    font-size: 20px;
    color: #0c456b;
    margin-bottom: 20px;
    margin-top: 5px;
    background-color: rgba(0,0,0,0);
    grid-column-start: 2;
    border: none;
    border-bottom: 2px solid blue;
    padding: 10px 15px;
    outline: none;
}

.contact-comment-inputBox-textarea {
    
    line-height: 25px;
    font-size: 20px;
    color: #0c456b;
    margin-top: 5px;
    background-color: rgba(0,0,0,0);
    grid-column-start: 2;
    grid-row-start: 4;  
    border: none;
    border-bottom: 2px solid blue;
    padding: 10px 15px;
    outline: none;
}

.contact-comment-submit-button {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
    width: 120px;
    color: #0c456b;
    background-color: rgba(0,0,0,0);
    border: 2px solid blue;
    padding: 10px;
    text-decoration: none;
    grid-column-start: 2;
    grid-row-start: 5;  
    justify-self: center;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}    

.contact-comment-submit-button:hover {
    background-color: rgba(17, 126, 242,0.2);
}

.contact-comment-submit-area {
    margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
    .contactForm-container {
        margin-top: 10px;
        grid-template-columns: 15% 70% 15%;
    }

    .contact-comment-label-email, .contact-comment-label-comment {
        font-size: 17px;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .contact-comment-inputBox-email {
        font-size: 17px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .contact-comment-inputBox-textarea {
        
        line-height: 15px;
        font-size: 17px;
        color: #0c456b;
        margin-top: 10px;
    }

    .contact-comment-submit-button {
        margin-top: 40px;
        margin-bottom: 50px;
        font-size: 17px;
        width: 100px;
        padding: 5px;
        justify-self: center;
    }    
}