.home-photo-forYou {
    height: 500px;
}

.home-photo-ble {
    height: 500px;
}

.home-photo-jessie {
    height: 500px;
}

.home-photo-walking {
    height: 500px;
}

.home-photo-pdf {
    height: 500px;
}

img {
    background-color: #fff;
    padding: 4px;
}

.carousel-banner {
    margin-top: 50px;
    margin-bottom: 200px;
}

@media only screen and (max-width: 600px) {
    .carousel-banner {
        margin-top: 25px;
        margin-bottom: 30px;
    }
}