.introduction {
    font-size: 20px;
    margin-left: 10%;
    margin-right: 10%;
    line-height: 35px;
    margin-bottom: 8%;
    margin-top: 2%;
    text-align: left;
}


.individual-app {
    margin-bottom: 2%;
}

@media only screen and (max-width: 600px) {
    .introduction {
        font-size: 18px;
        line-height: 25px;
    }
}


